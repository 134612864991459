import React from "react";
import { Card } from "../generic/Card";
import { InstagramLogo, TwitterLogo } from "@phosphor-icons/react";
import { LogoComponent } from "../generic/LogoComponent";

export const FooterCard = () => {
  return (
    <Card bg="bg-cardBg" classes="mt-2">
      <div className="flex font-MabryPro w-4/5 mx-auto py-10 max-sm:w-[85%] max-sm:flex-col">
        <div className="w-1/3 max-sm:w-full">
          <LogoComponent />
          <div className="flex pt-6 max-sm:hidden">
            <a href="https://www.instagram.com/putting.scenes/" target="_blank" rel="noreferrer">
              <InstagramLogo
                className="text-white bg-black m-3 p-2 rounded-full"
                size={38}
              />
            </a>

            <a href="https://twitter.com/puttingscenes" target="_blank" rel="noreferrer">
              <TwitterLogo
                className="text-white bg-black m-3 p-2 rounded-full"
                size={38}
                weight="fill"
              />
            </a>
          </div>
          <p className="w-4/5 pt-6 text-sm">
          372, 100 Feet Rd, HAL 2nd Stage, Indiranagar, Bengaluru, Karnataka 560008
          </p>
          <p className="w-4/5 font-bold pt-6 text-xs max-sm:hidden">
            Copyright 2024 © Putting Scene Ventures Pvt. Ltd All rights
            reserved.
          </p>
        </div>
        <div className="w-1/3 flex flex-col">
          <a href="/about-us" className="capitalize max-sm:pt-6">
            About us
          </a>
          <a href="/terms-of-use" className="capitalize pt-6">
            Terms of use
          </a>
          <a href="/privacy" className="capitalize pt-6">
            Privacy
          </a>
        </div>
        <div className="w-1/3 flex flex-col">
          <a href="/contact-us" className="capitalize max-sm:pt-6">
            Contact Us
          </a>
          <a href="/refund-and-cancellation" className="capitalize pt-6">
            Refund and cancellations
          </a>
        </div>
        <div className="flex pt-6max-sm:flex md:hidden">
          <a href="https://www.instagram.com/putting.scenes/" target="_blank" rel="noreferrer">
            <InstagramLogo
              className="text-white bg-black m-3 p-2 rounded-full max-sm:my-4 max-sm:mx-0"
              size={38}
            />
          </a>

          <a href="https://twitter.com/puttingscenes" target="_blank" rel="noreferrer">
            <TwitterLogo
              className="text-white bg-black m-3 p-2 rounded-full max-sm:my-4"
              size={38}
              weight="fill"
            />
          </a>
        </div>
        <p className="w-4/5 font-bold pt-6 text-xs max-sm:block max-sm:pt-2 md:hidden">
          Copyright 2024 © Putting Scene Ventures Pvt. Ltd All rights reserved.
        </p>
      </div>
    </Card>
  );
};
